.header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed ;
    top: 0;
    left:0;
    z-index: 9;
    background: #f3e1e1;
    box-shadow: 5px 5px 15px  -4px#fd2d1e;
}

.navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

}



.custom_search{
    display: flex;
    align-items: center;
    padding: 10px 25px;
    height: 35px;
    border-radius: 50px;
    background: #020202;
    color: white;
    justify-content: space-between;
    font-size: 14px;
    cursor:pointer
}

.custom_search:hover{
    transition: 0.6s;
    height: 38px;
}

.custom_search b{
    margin-left: 8px;
}


/*========= RESPONSIVE ===============*/

@media only screen and (max-width:700px){

    .custom_search {
        border-radius: 50%;
        padding: 10px;
        height: 40px;
    }
    .custom_search b{
        display:none
    }

}

