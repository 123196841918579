.footer{
    position:fixed ;
    bottom:0;
    width: 100%;
    display:none
 
}

.footer_bottom{
    background: black;
    padding: 10px 0px;
    text-align: center;
}

.footer_bottom p{
    font-size: 1rem;
    margin-bottom: 0;
    color:#fff
}

/*========= RESPONSIVE ===============*/

@media only screen and (max-width:700px){

    .footer_bottom p{
        font-size: 0.7rem;
    }

}