.single_product{
    border-radius: 10px;
    cursor: pointer;
    animation: animate 1s ease-in;
    box-sizing: border-box;
    background-color: #f0d1d1;
    border: 0 solid #dedede;
    box-shadow: 0 0 10px rgba(39, 32, 32, 0.2);
    text-align: center;
    padding: 20px;
}

.single_product:hover{
    box-shadow: 10px 10px 10px -5px #ee0202;
    transition: 1s
}

.product_content{
    padding:20px 20px;
}

.single_product h6{
    color: maroon;
    text-align: center;
    font-weight: 700; 
    font-size: 14px;
}

.single_product p{
    text-align: center;
    box-sizing: border-box;
    margin: 0 10px 10px;
    padding: 0;
    color: #000;
    font-family: roboto;
    font-weight: 300;
    font-style: normal;
    font-size: 13px;
}

.btn{
    height: 36px;
    box-sizing: border-box;
    margin-top: 10px ;
    border: 0 solid #000;
    border-radius: 4px;
    background-color: #fc0000;
    color: #fff;
    font-family: Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
}

.btn:hover{
    background: maroon;
}

@keyframes animate {
    from{
        transform: scale(0);
    }
    to{
        transform:(1);
    }
    
}

