.form{
    background: #8f6f6f;
    width: 500px;
    border-radius: 10px;
    color: black;
    padding: 30px;
    opacity: 0.1;
}




